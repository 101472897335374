import React, { useEffect, useState, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Card, 
  CardContent, 
  Grid, 
  List, 
  ListItem, 
  ListItemText,
  Divider,
  IconButton,
  LinearProgress,
  Paper,
  Link,
  Slider
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';

const HistoryItem = ({ callType, dateTime, duration, onClick, isSelected }) => (
  <ListItem 
    button 
    onClick={onClick}
    selected={isSelected}
    sx={{
      '&.Mui-selected': {
        backgroundColor: 'primary.light',
      },
      '&:hover': {
        backgroundColor: 'action.hover',
      },
    }}
  >
    <ListItemText 
      primary={callType}
      secondary={
        <React.Fragment>
          <Typography component="span" variant="body2" color="text.primary">
            {dateTime}
          </Typography>
        </React.Fragment>
      }
    />
  </ListItem>
);

const CallAnalysisItem = ({ label, value, complete }) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
    <Typography variant="body2">{label}</Typography>
    <Typography variant="body2" color={complete ? "success.main" : "text.secondary"}>
      {value}
    </Typography>
  </Box>
);

const CallInfoItem = ({ label, value }) => (
  <Box mb={1}>
    <Typography variant="body2" fontWeight="bold">{label}</Typography>
    <Typography variant="body2" style={{ wordBreak: 'break-all' }}>{value}</Typography>
  </Box>
);

const TranscriptItem = ({ role, content }) => (
  <Box mb={1}>
    <Typography variant="body2" fontWeight="bold" color={role === 'agent' ? 'primary' : 'secondary'}>
      {role === 'agent' ? 'Agent' : 'User'}:
    </Typography>
    <Typography variant="body2">{content}</Typography>
  </Box>
);

const HistoryContent = () => {
  const [historyData, setHistoryData] = useState([]);
  const [selectedCall, setSelectedCall] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioRef = useRef(null);

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const togglePlayPause = () => {
    if (audioRef.current) {
      if (isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const handleSliderChange = (event, newValue) => {
    if (audioRef.current) {
      audioRef.current.currentTime = newValue;
      setCurrentTime(newValue);
    }
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds.toString().padStart(2, '0')}`;
  };

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        setLoading(true);
        setError(null);
        const accessToken = localStorage.getItem('accessToken');
        
        if (!accessToken) {
          throw new Error('Access token is missing. Please log in again.');
        }

        const response = await fetch('https://api.aivira.co/history', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ /* Add any necessary body data here */ })
        });

        if (!response.ok) {
          throw new Error('Failed to fetch history data');
        }

        const data = await response.json();
        setHistoryData(data.call_history);
        if (data.call_history.length > 0) {
          setSelectedCall(data.call_history[0]);
        }
      } catch (error) {
        console.error('Error fetching history:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchHistory();
  }, []);

  const handleCallSelection = (call) => {
    setSelectedCall(call);
  };

  return (
    <Box sx={{ p: 2, height: '100vh', display: 'flex', flexDirection: 'column' }}>
      {loading ? (
        <LinearProgress />
      ) : error ? (
        <Typography variant="body1" color="error">Error: {error}</Typography>
      ) : (
        <Grid container spacing={2} sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <Grid item xs={12} md={3} sx={{ height: '100%', overflowY: 'auto' }}>
            <Typography variant="h6" gutterBottom>Call History</Typography>
            <Paper elevation={3}>
              <List>
                {historyData.map((item) => (
                  <React.Fragment key={item.call_id}>
                    <HistoryItem 
                      callType={item.call_type === 'web_call' ? 'Web Call' : 'Phone Call'}
                      dateTime={new Date(item.start_timestamp).toLocaleString()}
                      duration={formatDuration(item.duration)}
                      onClick={() => handleCallSelection(item)}
                      isSelected={selectedCall && selectedCall.call_id === item.call_id}
                    />
                    <Divider />
                  </React.Fragment>
                ))}
              </List>
            </Paper>
          </Grid>
          <Grid item xs={12} md={9} sx={{ height: '100%', overflowY: 'auto' }}>
            {selectedCall ? (
              <>
                <Typography variant="h6" gutterBottom>Call Details</Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <IconButton size="small" onClick={togglePlayPause}>
                      {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
                    </IconButton>
                    <Typography variant="body2" sx={{ mx: 1 }}>Call Recording</Typography>
                    <Typography variant="body2">{formatTime(currentTime)} / {formatTime(duration)}</Typography>
                  </Box>
                  <Slider
                    value={currentTime}
                    max={duration}
                    onChange={handleSliderChange}
                    aria-labelledby="continuous-slider"
                  />
                  <audio
                    ref={audioRef}
                    src={selectedCall.recording_url}
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleLoadedMetadata}
                    onEnded={() => setIsPlaying(false)}
                    style={{ display: 'none' }}
                  />
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">Call Analysis</Typography>
                        <CallAnalysisItem label="Call Summary" value={selectedCall.call_analysis.call_summary} complete={selectedCall.call_analysis.call_successful} />
                        <CallAnalysisItem label="User Sentiment" value={selectedCall.call_analysis.user_sentiment} complete={true} />
                        <CallAnalysisItem label="Call Completion" value={selectedCall.call_analysis.call_completion_rating} complete={selectedCall.call_analysis.call_completion_rating === "Complete"} />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">Call Information</Typography>
                        <CallInfoItem label="Call ID" value={selectedCall.call_id} />
                        <CallInfoItem label="Disconnection Reason" value={selectedCall.disconnection_reason} />
                        <CallInfoItem label="Recording URL" value={<Link href={selectedCall.recording_url} target="_blank">Listen to Recording</Link>} />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <CardContent>
                        <Typography variant="h6">Transcript</Typography>
                        {selectedCall.transcript_object.map((item, index) => (
                          <TranscriptItem key={index} role={item.role} content={item.content} />
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </>
            ) : (
              <Typography variant="body1">Select a call from the history to view details</Typography>
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default HistoryContent;
