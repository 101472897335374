import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from './Button';
import { Menu, Check, ChevronDown, ChevronUp, Phone } from 'lucide-react';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 py-4">
      <button
        className="flex justify-between items-center w-full text-left"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="text-lg font-semibold">{question}</h3>
        {isOpen ? <ChevronUp /> : <ChevronDown />}
      </button>
      {isOpen && <p className="mt-2 text-gray-600">{answer}</p>}
    </div>
  );
};

const HomePage = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [titleWord, setTitleWord] = useState('Small Businesses');
  const [demoType, setDemoType] = useState('phone');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const howItWorksRef = useRef(null);
  const featuresRef = useRef(null);
  const integrationsRef = useRef(null);
  const complianceRef = useRef(null);
  const faqRef = useRef(null);
  const videoRef = useRef(null);
  const pricingRef = useRef(null);

  const scrollTo = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
    setIsMenuOpen(false);
  };

  const handleGetTheCall = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch('https://api.aivira.co/create-phone-call', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from_number: '+17194262639',
          to_number: phoneNumber,
          name,
          email,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to create phone call');
      }

      // Handle successful response
      alert('Call request submitted successfully!');
      // Clear form fields
      setPhoneNumber('');
      setName('');
      setEmail('');
    } catch (err) {
      setError('An error occurred. Please try again.');
      console.error('Error:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const navItems = [
    { name: 'How it works', ref: howItWorksRef },
    { name: 'Features', ref: featuresRef },
    { name: 'Integrations', ref: integrationsRef },
    { name: 'Pricing', ref: pricingRef },
    { name: 'Compliance', ref: complianceRef },
    { name: 'FAQ', ref: faqRef },
  ];

  const videoUrl = "https://www.youtube.com/embed/2yTFkXkQfHI?autoplay=1&enablejsapi=1";

  const scrollToVideoAndPlay = () => {
    scrollTo(videoRef);
    const iframe = videoRef.current;
    if (iframe) {
      iframe.src = videoUrl;
      // For mobile devices, we need to manually start the video
      if (/Mobi|Android/i.test(navigator.userAgent)) {
        iframe.contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
      }
    }
  };

  const faqItems = [
    {
      question: "How does Aivira's implementation process work?",
      answer: "We start with a 30-day pilot where we work closely with your business to customize your AI receptionist (what information to collect for bookings, how to handle inquiries...etc). During this period, we deploy our software incrementally by first only handling a few calls a day, and gradually taking more until the end of the pilot. We are building a new, cutting edge technology and our goal is to get you comfortable with it before we manage 100% of your calls."
    },
    {
      question: "Is Aivira compatible with existing business software?",
      answer: "Yes, Aivira is designed to integrate seamlessly with a wide range of business management software. We support popular systems across various industries. If you use a specific system, please contact us to discuss compatibility."
    },
    {
      question: "How secure is Aivira's technology?",
      answer: "Aivira takes security and compliance very seriously. Our technology adheres to the highest industry standards, ensuring that all customer data is protected. We use advanced encryption methods, implement strict access controls, and regularly undergo security audits to maintain the integrity and confidentiality of your data."
    }
  ];

  const useCases = ['small-businesses', 'dentists', 'clinics', 'gyms', 'yoga-studios', 'car-dealerships'];

  useEffect(() => {
    const words = ['Small Businesses', 'Dentists', 'Clinics', 'Gyms', 'Yoga Studios', 'Car Dealerships'];
    let index = 0;
    const interval = setInterval(() => {
      setTitleWord(words[index]);
      index = (index + 1) % words.length;
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  const handleUseCaseClick = (useCase) => {
    // Navigate to the use case page
    window.location.href = `/use-case/${useCase}`;
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-purple-100 to-white flex flex-col">
      <div className="flex-grow">
      <header className="flex justify-between items-center p-4 max-w-7xl mx-auto">
        <div className="flex items-center space-x-12">
          <div className="text-3xl font-bold text-gray-800 flex items-center">
            <img src="/images/aivira-logo-head.png" alt="Aivira Logo" className="w-10 h-10" />
            <h2 className="text-4xl font-bold">Aivira</h2>
          </div>
          <nav className="hidden md:block">
            <ul className="flex space-x-8">
              {navItems.map((item) => (
                <li key={item.name}>
                  {item.ref ? (
                    <button onClick={() => scrollTo(item.ref)} className="text-gray-600 hover:text-gray-900">
                      {item.name}
                    </button>
                  ) : (
                    <a href={item.href} className="text-gray-600 hover:text-gray-900">{item.name}</a>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <div className="flex items-center space-x-4">
          <Button variant="ghost" className="hidden md:inline-flex" onClick={() => {
              if (typeof window !== 'undefined') {
                  const accessToken = localStorage.getItem('accessToken');
                  if (accessToken) {
                    window.location.href = '/dashboard';
                  } else {
                    window.open('https://api.aivira.co/login', '_self');
                  }
              }
            }}>Login
          </Button>
          <Button variant="default" className="bg-gray-900 hover:bg-gray-800 text-white" onClick={() => {
  if (typeof window !== 'undefined') {
    window.open('https://api.aivira.co/login', '_self');
  }
}}>Try For Free</Button>
          <Button variant="ghost" className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
            <Menu />
          </Button>
        </div>
      </header>

      {isMenuOpen && (
        <div className="md:hidden bg-white shadow-lg absolute right-0 mt-2 py-2 w-48 z-10">
          {navItems.map((item) => (
            <button
              key={item.name}
              onClick={() => item.ref ? scrollTo(item.ref) : null}
              className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
            >
              {item.name}
            </button>
          ))}
          <button
            onClick={() => {
              if (typeof window !== 'undefined') {
                const accessToken = localStorage.getItem('accessToken');
                if (accessToken) {
                  window.location.href = '/dashboard';
                } else {
                  window.open('https://api.aivira.co/login', '_self');
                }
              }
            }}
            className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left"
          >
            Login
          </button>
        </div>
      )}
      
      <main className="container mx-auto px-4 py-16">
        <section className="text-center mb-20">
          <h1 className="text-4xl md:text-6xl font-bold mb-4 text-left md:text-center">
            The AI Receptionist for <span className="text-purple-600 inline-block min-w-[200px] md:min-w-[300px] text-left">{titleWord}</span>
          </h1>
          <p className="text-xl mb-8 text-left md:text-center">Answers every call. Schedules appointments. Drives revenue.</p>
          <div className="space-x-4 mb-12">
            <Button size="lg" className="bg-gray-900 hover:bg-gray-800 text-white" onClick={() => {
  if (typeof window !== 'undefined') {
    window.open('https://api.aivira.co/login', '_self');
  }
}}>Try For Free</Button>
            <Button variant="outline" size="lg" onClick={scrollToVideoAndPlay}>Hear it in action</Button>
          </div>
          
          <div className="aspect-w-16 aspect-h-9 max-w-4xl mx-auto mb-12">
            <iframe
              ref={videoRef}
              src={videoUrl.replace('autoplay=1', 'autoplay=0')}
              title="Aivira Demo Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-[480px] rounded-lg shadow-lg"
            ></iframe>
          </div>

          <h2 className="text-3xl font-bold mb-6">Explore AI Receptionist for Different Industries</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
            {useCases.map((useCase, index) => (
              <div 
                key={index}
                className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer"
                onClick={() => handleUseCaseClick(useCase)}
              >
                <h3 className="text-lg font-semibold capitalize">{useCase.replace('-', ' ')}</h3>
                <p className="text-sm text-gray-600">Learn more</p>
              </div>
            ))}
          </div>
        </section>

        <section ref={howItWorksRef} className="mb-20">
          <h2 className="text-4xl font-bold mb-4 text-center">How Aivira works</h2>
          <p className="text-xl mb-12 text-center">Increase your efficiency and reduce your costs in 3 steps</p>
          
          <div className="grid md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-2xl font-semibold mb-2">Integrate with your software</h3>
              <p>1-click integration to your business management software</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-2">Forward calls to Aivira</h3>
              <p>Forward calls from any phone system to your Aivira number.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-2">Customize your calls</h3>
              <p>Easily teach your AI to handle calls just the way you like it.</p>
            </div>
          </div>

          <div className="mt-12">
            <h2 className="text-3xl font-bold mb-4 text-center">Aivira is the new way businesses handle phone calls</h2>
            <div className="flex flex-col items-center space-y-4 mt-8">
              <div className="bg-white rounded-full px-6 py-2 shadow-md">
                <Check className="inline-block mr-2 text-green-500" /> Available 24/7
              </div>
              <div className="bg-white rounded-full px-6 py-2 shadow-md">
                <Check className="inline-block mr-2 text-green-500" /> 5x cheaper than a traditional call-center
              </div>
              <div className="bg-white rounded-full px-6 py-2 shadow-md">
                <Check className="inline-block mr-2 text-green-500" /> Handles all inquiries, bookings, and customer service
              </div>
            </div>
          </div>
        </section>

        {/* Experience a Live Demo section removed */}

        <section ref={featuresRef} className="mb-20">
          <h2 className="text-4xl font-bold mb-12 text-center">Powerful Capabilities</h2>
          <div className="grid md:grid-cols-2 gap-12">
            <div>
              <h3 className="text-2xl font-semibold mb-2">Human-like Conversation</h3>
              <p>Engage customers with natural, lifelike interactions.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-2">Get 5-star Customer Experience Out of the Box</h3>
              <p>Deliver top-tier customer service from the start.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-2">SMS</h3>
              <p>Keep the conversation flowing even after the call ends.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-2">Effortlessly Manage Appointments</h3>
              <p>Streamline scheduling and reminders with ease.</p>
            </div>
            <div>
              <h3 className="text-2xl font-semibold mb-2">Multilingual</h3>
              <p>Communicate with customers in their preferred language for better connection.</p>
            </div>
          </div>
        </section>

        <section ref={integrationsRef} className="mb-20">
          <h2 className="text-4xl font-bold mb-8 text-center">Seamless integrations</h2>
          <p className="text-center mb-8">Our clients use a range of business management software and phone providers. Aivira plugs into your existing setup.</p>
          <div className="grid grid-cols-3 md:grid-cols-6 gap-8">
            {[
              { name: 'Salesforce', logo: '/images/salesforce-logo.jpg' },
              { name: 'Zendesk', logo: '/images/zendesk-logo.png' },
              { name: 'HubSpot', logo: '/images/hubspot-logo.png' },
              { name: 'Shopify', logo: '/images/shopify-logo.png' },
              { name: 'QuickBooks', logo: '/images/quickbooks-logo.jpg' },
              { name: 'Mailchimp', logo: '/images/mailchimp-logo.jpg' }
            ].map((item) => (
              <div key={item.name} className="bg-white p-4 rounded shadow-md flex items-center justify-center">
                <img src={item.logo} alt={`${item.name} logo`} className="max-w-full h-auto object-contain" style={{maxHeight: '60px'}} />
              </div>
            ))}
          </div>
        </section>

        <section ref={pricingRef} className="mb-20">
          <h2 className="text-4xl font-bold mb-8 text-center">Pricing</h2>
          <div className="max-w-4xl mx-auto">
            <div className="bg-white p-8 rounded-lg shadow-lg mb-8">
              <h3 className="text-2xl font-bold mb-4">Start for Free</h3>
              <ul className="list-disc list-inside mb-4">
                <li>No upfront costs — 60 minutes of free access to explore everything</li>
                <li>Plus, you'll get ongoing support through our Discord community</li>
              </ul>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-lg">
              <h3 className="text-2xl font-bold mb-4">Pricing Breakdown</h3>
              <div className="grid md:grid-cols-2 gap-8">
                <div>
                  <h4 className="text-xl font-semibold mb-2">Voice API for Calls:</h4>
                  <ul className="list-disc list-inside mb-4">
                    <li>Voices powered by ElevenLabs: $0.07 per minute</li>
                    <li>OpenAI / Deepgram voices: $0.08 per minute</li>
                  </ul>
                </div>
                <div>
                  <h4 className="text-xl font-semibold mb-2">AI Agents (LLM):</h4>
                  <ul className="list-disc list-inside mb-4">
                    <li>Aivira's GPT-4o mini: $0.006 per minute</li>
                    <li>Our full GPT-4o model: $0.05 per minute</li>
                    <li>Claude 3 (Haiku edition): $0.012 per minute</li>
                    <li>Claude 3.5 (Sonnet edition): $0.06 per minute</li>
                    <li>Or, if you've got your own LLM, it's free to plug in!</li>
                  </ul>
                </div>
                <div>
                  <h4 className="text-xl font-semibold mb-2">Telephony:</h4>
                  <ul className="list-disc list-inside mb-4">
                    <li>Using Aivira's Twilio: $0.01 per minute</li>
                    <li>Bringing your own Twilio? No extra charge.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section ref={faqRef} className="mb-20">
          <h2 className="text-4xl font-bold mb-12 text-center">Frequently Asked Questions</h2>
          <div className="max-w-3xl mx-auto">
            {faqItems.map((item, index) => (
              <FAQItem key={index} question={item.question} answer={item.answer} />
            ))}
          </div>
        </section>
      </main>
      </div>
      <footer className="bg-gray-800 text-white py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between">
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-xl font-bold mb-4">Aivira</h3>
              <p>The AI Receptionist for Small Businesses</p>
            </div>
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h3 className="text-xl font-bold mb-4">Quick Links</h3>
              <ul>
                {navItems.map((item, index) => (
                  <li key={index} className="mb-2">
                    {item.ref ? (
                      <button onClick={() => scrollTo(item.ref)} className="text-gray-300 hover:text-white">
                        {item.name}
                      </button>
                    ) : (
                      <a href={item.href} className="text-gray-300 hover:text-white">{item.name}</a>
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full md:w-1/3">
              <h3 className="text-xl font-bold mb-4">Legal</h3>
              <ul>
                <li className="mb-2">
                  <Link to="/privacy" className="text-gray-300 hover:text-white">Privacy Policy</Link>
                </li>
                <li className="mb-2">
                  <Link to="/terms" className="text-gray-300 hover:text-white">Terms and Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-8 text-center">
            <p>&copy; {new Date().getFullYear()} Aivira. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default HomePage;
