import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Sidebar from './Sidebar';
import AIAgentsContent from './AIAgentsContent';
import AIReceptionist from './AIReceptionist';
import PhonesContent from './PhonesContent';
import BillingContent from './BillingContent';
import HistoryContent from './HistoryContent';
import IntegrationsContent from './IntegrationsContent';
import { Menu } from 'lucide-react';

const Dashboard = () => {
  const [activePage, setActivePage] = useState('AI Receptionist');
  const [activeAgent, setActiveAgent] = useState('Single-Prompt Agent');
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth >= 768);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('access_token');
    if (token) {
      localStorage.setItem('accessToken', token);
      // Remove access_token from URL
      params.delete('access_token');
      navigate({
        pathname: location.pathname,
        search: params.toString(),
      }, { replace: true });
      // Refresh the page after setting the access token
      window.location.reload();
    } else {
      const accessToken = localStorage.getItem('accessToken');
  
      if (!accessToken) {
        window.location.href = "https://aivira.co/";
      } else {
        fetchPaymentMethods();
      }
    }
  }, [location.search, navigate]);

  const fetchPaymentMethods = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (!accessToken) {
        throw new Error('Access token is missing. Please log in again.');
      }

      const response = await fetch('https://api.aivira.co/payment_methods', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch payment methods');
      }

      const data = await response.json();
      setHasPaymentMethod(data.payment_methods && data.payment_methods.length > 0);
    } catch (error) {
      console.error('Error fetching payment methods:', error);
    }
  };

  const handleLogout = () => {
    const accessToken = localStorage.getItem('accessToken');
    fetch('https://api.aivira.co/logout', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    })
    .then(() => {
      localStorage.removeItem('accessToken');
      window.location.href = 'https://aivira.co';
    })
    .catch(error => {
      console.error('Logout failed:', error);
      // Even if logout fails, redirect to aivira.co
      window.location.href = 'https://aivira.co';
    });
  };

  const renderContent = () => {
    switch (activePage) {
      case 'AI Receptionist':
        return <AIReceptionist />;
      case 'Phones':
        return <PhonesContent />;
      case 'Billing':
        return <BillingContent />;
      case 'History':
        return <HistoryContent />;
      case 'Integrations':
        return <IntegrationsContent />;
      default:
        return null;
    }
  };

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar
        activePage={activePage}
        setActivePage={setActivePage}
        activeAgent={activeAgent}
        setActiveAgent={setActiveAgent}
        handleLogout={handleLogout}
        isOpen={sidebarOpen}
        setIsOpen={setSidebarOpen}
        hasPaymentMethod={hasPaymentMethod}
      />
      <div className="flex-1 flex flex-col">
        <div className="bg-white p-4 shadow-md flex items-center">
          <button onClick={() => setSidebarOpen(!sidebarOpen)} className="mr-4">
            <Menu size={24} />
          </button>
          <h1 className="text-xl font-bold">{activePage}</h1>
        </div>
        <div className="flex-1 p-8 overflow-auto">
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
